//-----------------------------------------------------------------------
// Carga asincrona JS Youtube
//-----------------------------------------------------------------------
var tag = document.createElement('script');
tag.src = "https://www.youtube.com/iframe_api";

var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

//Cuando esta cargada la API de youtube cargamos lo demas
function onYouTubeIframeAPIReady() {

    var id = $('#video-main').data('youtube');

    //Render Youtube Iframe
    var popYutube = new YT.Player('youtube' + id, {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 0
        },
        videoId: id,
        events: {
            'onStateChange': onPlayerStateChange
        }
    });

}

jQuery(document).ready(function ($) {

    // Cache vars
    var $nav = $('#main-nav');

    // deferYoutubeVideos
    $('.video-container').click(function(e){

        e.preventDefault();

        $e = $(this).find('[data-defervideo]');

        youTubeHandler($e)

    })

    //-----------------------------------------------------------------------
    // Prefetch home
    //-----------------------------------------------------------------------
    var pre_urls = [];
    $(".pagination .previous-box").each(function(){
        var url =  $(this).find('a').attr('href');
        pre_urls.push(url);
    });
    $(".pagination .next-box").each(function(){
        var url =  $(this).find('a').attr('href');
        pre_urls.push(url);
    });
    quicklink({
        urls: pre_urls
    });

    //Burguer
    $('.burguer').click( function(e) {

        e.preventDefault();

        $(this).toggleClass('burguer-active');
        $nav.toggleClass('main-nav-active');

    });

    //Navbar pos fixed on scroll
    $(window).scroll(navBarFixed);
    navBarFixed();

    //Video principal
    $('#video-main').click(function(e){

        e.preventDefault();

        var $this = $(this);
        var youtube_id = $(this).data('youtube');
        var $videoContainer = $this.parent();

        $this.remove();

        $videoContainer.append('<div class="resp-video"><div id="youtube'+youtube_id+'" class="youtubevideo"></div></div>');

        //Render Youtube Iframe
        var popYutube = new YT.Player('youtube' + youtube_id, {
            height: '390',
            width: '640',
            playerVars: {
                autoplay: 1
            },
            videoId: youtube_id,
            events: {
                'onStateChange': onPlayerStateChange
            }
        });

    });

    //-----------------------------------------------------------------------
    // Galerias
    // Depende de Photoswipe
    // El plugin NO pedende de jQuery
    //-----------------------------------------------------------------------

    var galleries = [];
    var pswpElement = document.querySelectorAll('.pswp')[0];
        
    if ($('[data-front="gallery"]').length) {

        $('[data-front="gallery"]').each(function(){

            var gallery_slides = [];

            $(this).children('li').each(function () {

                $this = $(this);
    
                var item = {
                    src: $this.find('a').attr('href'),
                    w: $this.find('a').attr('data-width'),
                    h: $this.find('a').attr('data-height'), 
                    name: $this.find('a').attr('data-name')
                }
    
                gallery_slides.push(item);
    
            });

            galleries.push(gallery_slides);

        });

        var gallery;
        var gallery_auto;

        $('body').on('click', '[data-front="gallery-trigger"]', function (e) {

            e.preventDefault();

            clearInterval(gallery_auto);

            var gallery_index = $(this).attr('data-index');

            var img_name = $(this).attr('data-name');

            var gallery_to_search = galleries[gallery_index];

            var li_index = 0;

            for (var i=0; i < gallery_to_search.length; i++) {
                if (gallery_to_search[i].name === img_name) {
                    li_index = i;
                }
            }

            var options = {
                index: li_index,
                shareButtons: [
                    { id: 'facebook', label: 'Comparte en Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}' },
                    { id: 'twitter', label: 'Comparte en Twitter', url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}' },
                    { id: 'pinterest', label: 'Pin it', url: 'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}' },
                    { id: 'download', label: 'Descargar Imagen', url: '{{raw_image_url}}', download: true }
                ],
            };

            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleries[gallery_index], options);

            gallery.init();

            // Autoplay de la galeria
            gallery_auto = setInterval( function() {
                gallery.next();
            }, 3000);

            gallery.listen('close', function() {
                clearInterval(gallery_auto);
            });

            $('.pswp__button--arrow--left').on('click',function(){
                clearInterval(gallery_auto);
            });

            $('.pswp__button--arrow--right').on('click',function(){
                clearInterval(gallery_auto);
            });

            $('.pswp__button--zoom').on('click',function(){
                clearInterval(gallery_auto);
            });

        });


    }

});

//-----------------------------------------------------------------------
// JS media queries
//-----------------------------------------------------------------------

function deviceDetect () {

    this.device = null;

    var cThis = this;

    //Resize eventos
    this.mq_mobile = window.matchMedia("(min-width: 576px) and (max-width: 767px)");
    this.mq_mobile.addListener(function(mql) {
        if (mql.matches) {
            cThis.device = 'mobile';
        }
    });

    this.mq_tablet = window.matchMedia("(min-width: 768px) and (max-width: 991px)");
    this.mq_tablet.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = 'tablet';
        }
    });

    this.mq_desktop = window.matchMedia("(min-width: 992px) and (max-width: 1199px)");
    this.mq_desktop.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = 'desktop';
        }
    });

    this.mq_desktopxl = window.matchMedia('(min-width: 1200px)');
    this.mq_desktopxl.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = 'desktoplg';
        }
    });

    //Cuando se carga la página
    if(this.mq_desktopxl.matches) {
        this.device = 'desktoplg';
    } else if (this.mq_desktop.matches) {
        this.device = 'desktop';
    } else if (this.mq_tablet.matches) {
        this.device = 'tablet';
    } else if (this.mq_mobile.matches) {
        this.device = 'mobile';
    } else {
        this.device = 'mobilesmall';
    }

}

//-----------------------------------------------------------------------
// YouTube API para trackerar los videos
//-----------------------------------------------------------------------

var video_tag = document.getElementById('youtube');
var x = document.getElementsByClassName('youtubevideo');
var player = new Array();

if (x.length > 0)
{
    /*
    //Crea el Iframe y el YouTubePlayer
    function onYouTubeIframeAPIReady() {

        for (var i = 0; i < x.length; i++)
        {

            var video_id = x[i].dataset.youtube;

            player[i] = new YT.Player('youtube' + video_id, {
                height: '390',
                width: '640',
                videoId: video_id,
                events: {
                    'onStateChange': onPlayerStateChange
                }
            });
            
        }
        //Trackea eventos
        var done = false;
    }
    */

    function onPlayerStateChange(event) {
        
        if (event.data == -1 ) {

            var titulo = event.target.getVideoData().title;
            //Esto es lo que le pasamos a Analytics
            gtag('event', 'video_play', { 'video_title': titulo });

        }

    }
    
}

//-----------------------------------------------------------------------
// Fixed on scroll
//-----------------------------------------------------------------------

function navBarFixed () {

    $toFix = $('#main-nav');
    $toFixB = $('#burguer');

    if ($(window).scrollTop() > 130) {

        $toFix.addClass('main-nav-fixed');
        $toFixB.addClass('burguer-anesvad-fixed');

    } else {

        $toFix.removeClass('main-nav-fixed');
        $toFixB.removeClass('burguer-anesvad-fixed');

    }

}

//-----------------------------------------------------------------------
// Defer youtube videos
//-----------------------------------------------------------------------

function youTubeHandler($element) {

    var youtube_defer = $element[0];

    var video = youtube_defer;

    if( isElementInViewport(video) ) {

        var video_source = video.getAttribute('data-src');

        var template = '<div class="responsive-video" style="position:relative;padding-bottom: 56.25%;"><iframe style="position:absolute;top:0;left:0;" height="100%" width="100%" src="https://www.youtube.com/embed/'+ video_source +'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in picture" allowfullscreen=""></iframe></div>';

        video.innerHTML = template;

        video.removeAttribute('data-defervideo');

    }

}

function isElementInViewport(element) {

    var rect = element.getBoundingClientRect();

    return (
        rect.top <= (self.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (self.innerWidth || document.documentElement.clientWidth) &&
        rect.right >= 0 &&
        rect.bottom >= 0
    );

}